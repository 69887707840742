<script>
import AreaForm from "@/views/menu/area/areaForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    areaService: "areaService",
  },
})
export default class AreaDetail extends mixins(AreaForm) {
  formId = "detail-area-form";

  get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle() {
    return this.translations.pageTitles.areas_detail;
  }

  async afterCreate() {
    this.model = await this.areaService.read(this.$route.params.id);
    this.isReady = true;
    this.model.entityLink = Utility.generateEntityLink(
      this?.event,
      "Area",
      this.model.id
    );
  }
}
</script>
